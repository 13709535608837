<template>
    <component :is="SkinComponent" v-model="user" @submit="submit"></component>
</template>

<script>
import api from "@/api";
import CryptoAES from "@/plugins/crypto-aes";
import { initUser } from "@/assets/variables";
import { mapActions } from "vuex";

export default {
    props: ["skin", "code"],
    data() {
        return {
            user: initUser({
                _terms: this.$store.state._terms || []
            }),
            areas: [],
        };
    },
    computed: {
        SkinComponent() {
            return () => import(`./skin/${this.$props.skin}/form.vue`);
        },
        _certification() {
            return this.$route.query?._certification;
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapActions(["login"]),

        async init() {
            if(!this._certification) {
                alert("본인인증이 필요합니다.");
                this.$router.go(-1);
            }

            await this.search();
        },

        async search() {
            try {
                let { name, phone, birthday, ci } = await api.v1.cert.get({ _id: this._certification });
                
                this.user = initUser({...this.user, name, phone: phone.phoneNumberFormat(), birthday: birthday.slice(2), ci: CryptoAES.decrypt(ci)});

            } catch(error) {
                this.$handleError(error);
            }
        },

        async submit(value) {
            try {
                ////////////////////////////////////////////////////////////////
                // 폼데이터 정돈 
                ////////////////////////////////////////////////////////////////
                let { address, ...form } = value;

                const _terms = this.$store.state.agreements._terms.map(({ _id }) => _id);

                form._terms = _terms;
                form.password = CryptoAES.encrypt(value.password);

                ////////////////////////////////////////////////////////////////
                // 회원 생성/로그인
                ////////////////////////////////////////////////////////////////
                await api.v1.users.post({...form, ...address});

                let { username, password } = value || {};
                await this.login({ username, password });

                this.$router.push({ path: "/join/complete" });
            } catch (error) {
                this.$handleError(error);
            }
        }
    },
};
</script>
