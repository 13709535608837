var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "wrap--light"
  }, [_c('page-section', {
    staticClass: "page-section--first page-section--last"
  }, [_c('h2', {
    staticClass: "join-title"
  }, [_vm._v("회원가입")]), _c('v-row', {
    staticClass: "row--xl",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "xl": "6"
    }
  }, [_c('v-card', {
    staticClass: "rounded-lg pa-20 px-md-84 py-md-60",
    attrs: {
      "outlined": ""
    }
  }, [_c('terms-of-agreements-short', _vm._b({
    on: {
      "isValid": function (isValid) {
        return _vm.isTermsValid = isValid;
      }
    },
    model: {
      value: _vm.terms,
      callback: function ($$v) {
        _vm.terms = $$v;
      },
      expression: "terms"
    }
  }, 'terms-of-agreements-short', {
    params: {
      code: {
        $nin: ['order']
      }
    }
  }, false)), _c('v-card-actions', {
    staticClass: "pa-0 pt-12 pt-md-16"
  }, [_c('v-btn', {
    attrs: {
      "block": "",
      "color": "#FF3A4A",
      "dark": "",
      "x-large": ""
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("본인인증 하기")])], 1)], 1)], 1)], 1)], 1), _c('kcp-cert', {
    ref: "kcpCert",
    on: {
      "input": _vm.verify
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }