var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "wrap--light"
  }, [_c('page-section', {
    staticClass: "page-section--first page-section--last"
  }, [_c('h2', {
    staticClass: "join-title"
  }, [_vm._v("회원가입")]), _c('div', {
    staticClass: "join-container"
  }, [_c('join-form', {
    attrs: {
      "code": "USER_INDIVIDUAL",
      "skin": "USER_INDIVIDUAL"
    }
  })], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }