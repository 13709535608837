var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "wrap--light"
  }, [_c('page-section', {
    staticClass: "page-section--first page-section--last"
  }, [_c('div', {
    staticClass: "join-container"
  }, [_c('v-layout', {
    staticClass: "text-center",
    attrs: {
      "column": "",
      "align-center": ""
    }
  }, [_c('v-img', {
    staticClass: "mb-18 mb-md-24",
    attrs: {
      "max-width": "60",
      "src": "/images/icon/icon-check-circle-outline.svg"
    }
  }), _c('h4', {
    staticClass: "tit"
  }, [_vm._v("회원가입이 완료되었습니다.")]), _vm.hasAdditionalForm ? [_c('p', {
    staticClass: "page-text page-text--lg grey--text mt-16 mt-md-20"
  }, [_vm._v(" 아래의 추가 입력 정보를 입력해 주세요."), _c('br'), _vm._v(" 지금 입력하지 않아도 다음에 입력하실 수 있습니다. ")]), _c('join-additional-form', _vm._b({
    staticClass: "mt-30 mt-md-60"
  }, 'join-additional-form', {
    type: _vm.type,
    kind: _vm.kind
  }, false))] : _vm._e(), _c('v-btn', {
    staticClass: "rounded-xs mt-40 mt-md-48",
    attrs: {
      "to": "/",
      "x-large": "",
      "rounded": "",
      "dark": "",
      "color": "primary"
    }
  }, [_c('span', {
    staticClass: "tit tit--xxs"
  }, [_vm._v("메인으로 이동")])])], 2)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }