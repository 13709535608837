<template>
    <v-dialog v-model="shows" v-bind="{ ...$attrs, width, maxWidth }" name="modal" scrollable>
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on, userTypeMatches }" />
        </template>
        <v-card v-bind="{ loading }" tile>
            <v-toolbar>
                <v-toolbar-title class="d-flex align-center">
                    <template v-if="this.$slots['titleContents']">
                        <slot name="titleContents" />
                    </template>
                    <span v-else>{{ title }}</span>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon class="transparent" @click="close"><v-icon>mdi-close</v-icon></v-btn>
            </v-toolbar>
            <v-card-text class="px-24px px-lg-60px overflow-x-hidden" :class="$slots['actions'] ? 'pb-0px' : 'pb-30px pb-lg-60px'">
                <template v-if="showsContents">
                    <slot />
                </template>
                <template v-else-if="!userHasLoggedIn">
                    <v-layout fill-height column justify-center align-center>
                        <v-sheet width="400" max-width="100%">
                            <login-card />
                        </v-sheet>
                    </v-layout>
                </template>
                <temlpate v-else>
                    <v-layout fill-height column justify-center align-center>
                        <v-sheet width="400" max-width="100%">
                            <v-responsive :aspect-ratio="4 / 3">
                                <v-sheet class="d-flex flex-column justify-center text-center fill-height">
                                    <span class="font-size-20">
                                        <b> {{ storeUserTypeText ?? "관리자" }} </b> 사용자 유형으로는 <br />
                                        사용할 수 없는 기능입니다.
                                    </span>
                                </v-sheet>
                            </v-responsive>
                        </v-sheet>
                    </v-layout>
                </temlpate>
            </v-card-text>
            <v-card-actions v-if="$slots['actions']" class="px-0px pt-30px pb-0px pb-md-30px px-lg-12px pt-lg-40px pb-lg-50px">
                <slot name="actions" />
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { USER_TYPES } from "@/assets/variables";

export default {
    components: {
        // LoginCard: () => import("@/components/client/login/login-form.vue"),
    },
    props: {
        value: { type: Boolean, default: false }, // shows
        title: { type: String },
        width: { type: [Number, String], default: undefined },
        maxWidth: { type: [Number, String], default: undefined },

        loading: { type: Boolean, default: false },
        needsLogin: { type: Boolean, default: false },

        userType: { type: [Array, String], default: null },
    },
    data: () => ({
        shows: false,
    }),
    computed: {
        doesNotMatch() {
            return this.shows != this.value;
        },
        storeUserType() {
            return this.$store.state.payload?.userType;
        },
        storeUserTypeText() {
            return USER_TYPES[this.storeUserType]?.text;
        },
        userHasLoggedIn() {
            return this.$store.state.payload?._user;
        },
        userTypeMatches() {
            if (!this.userType) return true;
            if (typeof this.userType == "string") return this.userType == this.storeUserType;
            if (Array.isArray(this.userType)) return this.userType.includes(this.storeUserType);
        },
        showsContents() {
            return !this.needsLogin || (this.userHasLoggedIn && this.userTypeMatches);
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            if (this.doesNotMatch) this.sync();
        },
        shows() {
            if (this.doesNotMatch) this.emit();
        },
    },
    methods: {
        sync() {
            this.shows = this.value;
        },
        emit() {
            this.$emit("input", this.shows);
        },
        close() {
            this.shows = false;
        },
    },
};
</script>

<style lang="scss" scoped></style>
