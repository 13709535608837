var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', [_c('div', {
    staticClass: "join-caption join-caption--block"
  }, [_c('v-checkbox', {
    attrs: {
      "checked": _vm.termsList.length == _vm.selected.length,
      "label": "약관에 전체 동의합니다.",
      "color": "tertiary",
      "hide-details": ""
    },
    on: {
      "click": function ($event) {
        _vm.selected = _vm.termsList.length == _vm.selected.length ? [] : _vm.termsList.map(function (terms) {
          return terms._id;
        });
      }
    }
  })], 1)]), _c('v-card', {
    staticClass: "pa-8 pa-md-20",
    attrs: {
      "flat": "",
      "tile": "",
      "outlined": ""
    }
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [[_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "label": "약관 이름이 옵니다.",
      "color": "tertiary",
      "hide-details": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "page-text"
  }, [_vm._v("   "), [_c('span', {
    staticClass: "tertiary--text"
  }, [_vm._v("(필수)")])], [_c('span', {
    staticClass: "grey--text"
  }, [_vm._v("(선택)")])]], 2)]), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('dialogue', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('div', _vm._g(_vm._b({
          staticClass: "page-text grey--text text--grey-lighten-1 cursor-pointer"
        }, 'div', attrs, false), on), [_vm._v("내용보기")])];
      }
    }]),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-card', [_c('v-card-text', [_c('div', {
    staticClass: "join-box"
  })])], 1)], 1)], 1)], 1)], 1)]], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }