<template>
    <terms-dialog v-model="selected" v-bind="{ params }" @isLoaded="setTermsList" @input="(value) => $emit('input', value)" @isValid="(isValid) => $emit('isValid', isValid)">
        <template v-slot:activator="{ on, isAllSelected }">
            <div v-on="on">
                <div class="pb-12">
                    <v-checkbox hide-details readonly label="약관에 전체 동의합니다." :value="isAllSelected" />
                </div>
                <v-card tile outlined v-if="!hideItems" class="pa-8 pa-md-20 inner-terms">
                    <v-checkbox v-for="(terms, index) in termsList" :key="terms._id" :value="_selected.includes(terms._id)" hide-details readonly :class="{ 'mt-8 mt-md-12': index != 0 }">
                        <template #label>
                            {{ terms.subject }}&nbsp;
                            <span :class="[terms.necessary ? 'tertiary--text' : 'grey--text text--lighten-1']">({{ terms.necessary ? "필수" : "선택" }})</span>
                            <v-spacer />
                            <span class="page-text page-text--sm grey--text text--grey-lighten-1">내용보기</span>
                        </template>
                    </v-checkbox>
                </v-card>
            </div>
        </template>
    </terms-dialog>
</template>

<script>
import { checkbox_primary } from "@/assets/variables";

import TermsDialog from "./terms-dialog.vue";

export default {
    components: {
        TermsDialog,
    },
    props: {
        value: { type: Array, default: () => [] },
        params: { type: Object, default: () => ({}) },
        hideItems: { type: Boolean, default: false },
    },
    data: () => ({
        checkbox_primary,

        termsList: [],
        selected: [],
    }),
    computed: {
        _selected() {
            return this.selected.map(({ _id }) => _id);
        },
    },
    methods: {
        setTermsList(termsList) {
            this.termsList = termsList;
        },
    },
};
</script>

<style lang="scss" scoped>
.inner-terms {
    ::v-deep {
        .v-label {
            font-size: 12px;
        }
    }
}
</style>
