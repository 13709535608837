var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "title": "이용약관",
      "persistent": "",
      "width": "600",
      "scrollable": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on,
          isAllSelected: _vm.isAllSelected
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_vm.isLoaded ? _c('div', [_c('v-card', {
    attrs: {
      "tile": ""
    }
  }, [_c('v-toolbar', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-toolbar-title', {
    staticClass: "d-flex align-center font-weight-bold"
  }, [_vm._v("이용약관")]), _c('v-spacer'), _c('v-btn', {
    staticClass: "transparent",
    attrs: {
      "icon": ""
    },
    on: {
      "click": function () {
        return _vm.shows = false;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('v-card-text', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', {
    staticClass: "v-size--large",
    attrs: {
      "hide-details": "",
      "readonly": "",
      "label": "약관에 전체 동의합니다."
    },
    on: {
      "click": _vm.toggleSelectedAll
    },
    model: {
      value: _vm.isAllSelected,
      callback: function ($$v) {
        _vm.isAllSelected = $$v;
      },
      expression: "isAllSelected"
    }
  })], 1)], 1), _c('v-divider', {
    staticClass: "mt-14"
  }), _vm._l(_vm.termsList, function (term) {
    return _c('div', {
      key: term._id
    }, [_c('div', {
      staticClass: "my-14"
    }, [_c('v-row', {
      attrs: {
        "align": "center",
        "no-gutters": ""
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_vm._v(" " + _vm._s(term.subject) + " "), _c('span', {
      class: [term.necessary ? 'tertiary--text' : 'grey--text text--lighten-1']
    }, [_vm._v("(" + _vm._s(term.necessary ? "필수" : "선택") + ")")])]), _c('v-spacer'), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-checkbox', {
      attrs: {
        "hide-details": "",
        "value": {
          _id: term._id,
          code: term.code
        },
        "label": "약관동의"
      },
      model: {
        value: _vm.selected,
        callback: function ($$v) {
          _vm.selected = $$v;
        },
        expression: "selected"
      }
    })], 1)], 1)], 1), _c('v-card', {
      attrs: {
        "outlined": "",
        "rounded": "0"
      }
    }, [_c('v-sheet', {
      staticClass: "pa-16 pa-md-20",
      staticStyle: {
        "overflow-y": "auto"
      },
      attrs: {
        "min-height": "120"
      }
    }, [_c('div', {
      domProps: {
        "innerHTML": _vm._s(term.content.replace(/\n/gi, '<br/>'))
      }
    })])], 1)], 1);
  })], 2), _c('v-card-actions', [_c('div', {
    staticClass: "btn-wrap w-100"
  }, [_c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', [_c('v-btn', {
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "outlined": "",
      "color": "grey"
    },
    on: {
      "click": _vm.cancel
    }
  }, [_vm._v("취소")])], 1), _c('v-col', [_c('v-btn', {
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "color": "primary",
      "disabled": !_vm.isValid
    },
    on: {
      "click": _vm.agree
    }
  }, [_vm._v(" 동의 ")])], 1)], 1)], 1)])], 1)], 1) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }