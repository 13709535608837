<template>
    <v-dialog v-model="shows" title="이용약관" persistent width="600" scrollable>
        <template v-slot:activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on, isAllSelected }" />
        </template>
        <div v-if="isLoaded">
            <v-card tile>
                <v-toolbar flat>
                    <v-toolbar-title class="d-flex align-center font-weight-bold">이용약관</v-toolbar-title>
                    <v-spacer />
                    <v-btn icon class="transparent" @click="() => (shows = false)"><v-icon>mdi-close</v-icon></v-btn>
                </v-toolbar>

                <v-card-text>
                    <v-row no-gutters>
                        <v-spacer />
                        <v-col cols="auto">
                            <v-checkbox v-model="isAllSelected" hide-details readonly label="약관에 전체 동의합니다." class="v-size--large" @click="toggleSelectedAll" />
                        </v-col>
                    </v-row>

                    <v-divider class="mt-14" />

                    <div v-for="term in termsList" :key="term._id">
                        <div class="my-14">
                            <v-row align="center" no-gutters>
                                <v-col cols="auto">
                                    {{ term.subject }}&nbsp;<span :class="[term.necessary ? 'tertiary--text' : 'grey--text text--lighten-1']">({{ term.necessary ? "필수" : "선택" }})</span>
                                </v-col>
                                <v-spacer />
                                <v-col cols="auto">
                                    <v-checkbox v-model="selected" hide-details :value="{ _id: term._id, code: term.code }" label="약관동의" />
                                </v-col>
                            </v-row>
                        </div>
                        <v-card outlined rounded="0">
                            <v-sheet min-height="120" class="pa-16 pa-md-20" style="overflow-y: auto"><div v-html="term.content.replace(/\n/gi, '<br/>')" /></v-sheet>
                        </v-card>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <div class="btn-wrap w-100">
                        <v-row class="row--sm">
                            <v-col>
                                <v-btn block outlined color="grey" class="v-size--xx-large" @click="cancel">취소</v-btn>
                            </v-col>
                            <v-col>
                                <v-btn block color="primary" class="v-size--xx-large" :disabled="!isValid" @click="agree"> 동의 </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </v-card-actions>
            </v-card>
        </div>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { attrs_input } from "@/assets/variables";
import { checkbox_primary } from "@/assets/variables";

import PageSection from "@/components/client/sub/page-section.vue";
import UDialog from "../dumb/u-dialog.vue";
import TitWrapXSmall from "@/components/client/dumb/tit-wrap-x-small.vue";
import VPreviewInput from "@/components/plugins/vuetify/v-preview-input.vue";

export default {
    components: {
        PageSection,
        UDialog,
        TitWrapXSmall,
        VPreviewInput,
    },
    props: {
        value: { type: Array, default: () => [] },
        params: { type: Object, default: () => ({}) },
    },
    data: () => ({
        checkbox_primary,

        shows: false,
        isLoaded: false,
        selected: [],
        termsList: [],

        attrs_input,
    }),
    computed: {
        isValid() {
            let selected = this.selected.map(({ _id }) => _id);
            return this.termsList.filter(({ necessary }) => necessary).every(({ _id }) => selected.includes(_id));
        },
        isAllSelected() {
            let selected = this.selected.map(({ _id }) => _id);
            return this.termsList.every(({ _id }) => selected.includes(_id));
        },
    },
    created() {
        this.init();
    },
    mounted() {
        this.selected = this.value;
    },
    watch: {
        shows() {
            if (this.shows) this.init();
        },

        isLoaded(isLoaded) {
            if (isLoaded) this.$emit("isLoaded", this.termsList);
        },
    },
    methods: {
        async init() {
            try {
                this.isLoaded = false;

                let { params } = this;
                var { termsList } = await api.v1.terms.gets({ params });
                this.termsList = termsList;

                this.isLoaded = true;
            } catch (error) {
                this.$handleError(error);
            }
        },

        toggleSelectedAll() {
            if (this.isAllSelected) this.selected = [];
            else
                this.selected = this.termsList.map(({ _id, code }) => {
                    return { _id, code };
                });
            // else this.selected = this.termsList.map({ _id } => _id);
        },
        cancel() {
            this.selected = this.value;
            this.shows = false;
        },
        agree() {
            this.$emit("input", this.selected);
            this.$emit("isValid", this.isValid);
            this.shows = false;
        },
    },
};
</script>

<style></style>
