<template>
    <div>
        <!-- 전체동의 -->
        <div>
            <div class="join-caption join-caption--block">
                <v-checkbox :checked="termsList.length == selected.length" label="약관에 전체 동의합니다." color="tertiary" hide-details @click="selected = termsList.length == selected.length ? [] : termsList.map((terms) => terms._id)" />
            </div>
        </div>
        <v-card flat tile outlined class="pa-8 pa-md-20">
            <v-row class="row--xs">
                <template>
                    <v-col cols="12">
                        <v-row align="center" no-gutters>
                            <v-col cols="auto">
                                <v-checkbox label="약관 이름이 옵니다." color="tertiary" hide-details />
                            </v-col>
                            <v-col cols="auto">
                                <div class="page-text">
                                    &nbsp;
                                    <template>
                                        <span class="tertiary--text">(필수)</span>
                                    </template>
                                    <template>
                                        <span class="grey--text">(선택)</span>
                                    </template>
                                </div>
                            </v-col>
                            <v-spacer />
                            <v-col cols="auto">
                                <dialogue v-model="shows">
                                    <template #activator="{ on, attrs }">
                                        <div v-on="on" v-bind="attrs" class="page-text grey--text text--grey-lighten-1 cursor-pointer">내용보기</div>
                                    </template>

                                    <v-card>
                                        <v-card-text>
                                            <div class="join-box">
                                                <!-- <p class="textarea" name="terms" readonly v-html="terms.content" /> -->
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </dialogue>
                            </v-col>
                        </v-row>
                    </v-col>
                </template>
            </v-row>
        </v-card>
    </div>
</template>

<script>
import api from "@/api";
import dialogue from "../dumb/dialogue.vue";
export default {
    components: { dialogue },
    props: ["code"],
    data() {
        return {
            selected: [],
            termsList: [],

            filter: {
                code: this.$props.code || undefined,
            },
            shows: false,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                var { termsList } = await api.v1.terms.gets({ params: this.filter });
                this.termsList = termsList;
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        check() {
            try {
                this.termsList.forEach((terms) => {
                    if (terms.necessary && this.selected.indexOf(terms._id) < 0) {
                        throw new Error(`[${terms.subject}]에 동의해 주세요`);
                    }
                });

                return true;
            } catch (error) {
                alert(error.message);
                return false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .v-input--checkbox {
        .v-label {
            font-size: 14px;
        }
    }
}

@media (min-width: 768px) {
    ::v-deep {
        .v-input--checkbox {
            .v-label {
                font-size: 16px;
            }
        }
    }
}
</style>