<template>
    <component :is="SkinComponent" v-model="user" v-on="{ save }"></component>
</template>

<script>
import api from "@/api";
import { mapActions } from "vuex";

export default {
    props: {
        type: { type: String },
        kind: { type: String },
    },
    data: () => ({
        user: {},
    }),
    computed: {
        ...mapActions(["login"]),
        SkinComponent() {
            return () => import(`./skin/${this.$props.type}/additional-form.vue`);
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            if (!this.$store.state.accessToken) {
                this.$router.push({
                    path: "/login",
                    params: this.$router.params,
                });
                return;
            }

            var { user } = await api.v1.me.get();
            var [phone1, phone2, phone3] = (user.phone || "").phoneNumberFormat().split("-");
            const avatar = user?.avatar?.url ? await api.getResource(user.avatar.url, true) : null;
            const brochures = await Promise.all((user?.brochures || [])?.map(async ({ url }) => api.getResource(url, true)));

            this.user = {
                ...user,
                phone1,
                phone2,
                phone3,
                avatar,
                brochures,
                establishedAt: user?.establishedAt?.toDate?.(),
            };
        },

        async save(data) {
            try {
                let { _avatar, avatar, _brochures, brochures, ...user } = data;

                delete user.password;
                delete user.subPassword;
                delete user.scope;
                delete user.mes;

                delete user.isBlock;
                delete user.isExpert;
                delete user.isWarning;
                delete user.isApproval;
                delete user.isDormancy;
                delete user.isDisplayed;
                delete user.isWithdrawal;
                delete user.isContactDisplayed;

                delete user.createdAt;
                delete user.visitedAt;
                delete user.dormancedAt;
                delete user.withdrawnAt;

                let _user = user._id;
                if (_avatar) {
                    await api.v1.users.avatar.delete({ _id: _avatar, _user });
                }
                for (const _id of _brochures || []) {
                    await api.v1.users.brochures.delete({ _id, _user });
                }

                user = (await api.v1.me.put(user))?.user;

                if (avatar) {
                    await api.v1.users.avatar.post({ _user }, avatar);
                }

                if (brochures?.length) {
                    for (const [index, brochure] of brochures.entries()) {
                        await api.v1.users.brochures.post({ _user, index }, brochure);
                    }
                }

                alert("추가정보가 저장되었습니다.");

                this.$router.push({ path: "/" });
            } catch (error) {
                this.$handleError(error);
            }
        },
    },
};
</script>
