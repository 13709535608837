<template>
    <client-page class="wrap--light">
		<!-- 메인 -->
		<page-section class="page-section--first page-section--last">
            <h2 class="join-title">회원가입</h2>

            <div class="join-container">
                <join-form code="USER_INDIVIDUAL" skin="USER_INDIVIDUAL" />
            </div>
		</page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import JoinForm from "@/components/client/join/join-form.vue";

export default {
    components: {
		ClientPage,
		PageSection,
        JoinForm,
    },
    computed: {
        code() {
            return this.$route.query?.code;
        },
    },
};
</script>
