<template>
    <client-page class="wrap--light">
        <page-section class="page-section--first page-section--last">
            <h2 class="join-title">회원가입</h2>

            <v-row justify="center" class="row--xl">
                <v-col cols="12" xl="6">
                    <v-card outlined class="rounded-lg pa-20 px-md-84 py-md-60">
                        <!-- <terms-of-agreements ref="terms" /> -->
                        <terms-of-agreements-short v-model="terms" v-bind="{ params: { code: { $nin: ['order'] } }}"  @isValid="(isValid) => (isTermsValid = isValid)"/>
                        <v-card-actions class="pa-0 pt-12 pt-md-16">
                            <v-btn block color="#FF3A4A" dark x-large @click="submit">본인인증 하기</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </page-section>
        <kcp-cert ref="kcpCert" @input="verify" />
    </client-page>
</template>

<script>
import api from "@/api";
import cryptoAes from "@/plugins/crypto-aes";
import KcpCert from "@/components/plugins/kcp/kcp-cert.vue";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import TermsOfAgreements from "@/components/client/join/terms-of-agreements.vue";
import TermsOfAgreementsShort from "@/components/client/join/terms-of-agreements-short.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        TermsOfAgreements,
        TermsOfAgreementsShort,
        KcpCert,
    },
    data: () => ({
        terms: [],
    }),
    methods: {
        submit() {
            try {
                this.$store.dispatch("agreements", { _terms: this.terms });
                this.$refs.kcpCert.auth();
            } catch (error) {
                alert(error.message.toString());
            }
        },
        async verify(payload) {
            try {
                let { _certification, ci } = payload;
                ci = cryptoAes.encrypt(ci.replace(/[ ]/g, "+"));
                const { _user } = await api.v1.users.existence.post({ ci });

                if (_user) throw new Error("이미 가입된 회원입니다.");

                this.$router.push({ path: "./join/form", query: { _certification } });
            } catch (error) {
                this.$handleError(error);
            }
        },
    },
};
</script>
